import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { ObjectPool } from '../object-pool';

export class PendingAttachment extends ApplicationRecord {
  recordAttachmentName: string;
  fileName: string;
  state: string;
  recordType: string;
  recordId: string;
  updatedAt: string;

  static prefix = 'pendingAttachments' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.recordAttachmentName = this.attribute(
      'recordAttachmentName',
      z.string()
    );
    this.fileName = this.attribute('fileName', z.string());
    this.state = this.attribute('state', z.string());
    this.recordType = this.attribute('recordType', z.string());
    this.recordId = this.attribute('recordId', z.string());
    this.updatedAt = this.attribute('updatedAt', z.string().datetime());
  }
}
