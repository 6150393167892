import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { ObjectPool, RegisteredModelPrefix } from '../object-pool';
import { PendingAttachment } from '../pending-attachment/model';
import { User } from '../user/model';

export class Photo extends ApplicationRecord {
  uploaderId: string;
  discussionId?: string | null;
  fileUrl?: string | null;
  createdAt: string;

  static prefix = 'photos' as const;
  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.uploaderId = this.attribute('uploaderId', z.string());
    this.discussionId = this.attribute('discussionId', z.string().nullish());
    this.fileUrl = this.attribute('fileUrl', z.string().nullish());
    this.createdAt = this.attribute('createdAt', z.string().datetime());
  }

  get uploader() {
    return this.belongsToOptional(User, this.uploaderId);
  }

  get pendingAttachment() {
    return this.hasOne(PendingAttachment, 'recordId');
  }
}
